@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Monserrat, system-ui, sans-serif;
  }
}

/* body,
html {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
